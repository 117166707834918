import React, {useEffect} from 'react';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
    const scaleForm = () => {
      const screenWidth = Math.min(window.innerWidth, window.outerWidth);
      const scaleFactor = Math.min(1.5, 1 + (screenWidth - 600) / 600);
      const f = document.querySelector('.f');
      if (f === null) return;
  
      f.style.transformOrigin = '0 0';
      f.style.transition = 'none';
      f.style.transform = `scale(${scaleFactor})`;
      f.style.marginLeft = `calc( (${screenWidth} - 500*${scaleFactor}) / 2 * 1px)`;
  
      console.log(`Screen Width: ${screenWidth}, Scale Factor: ${scaleFactor}`);
    };
  
    useEffect(() => {
      scaleForm(); 
      window.addEventListener('resize', scaleForm);
  
      return () => {
        window.removeEventListener('resize', scaleForm);
      };
    }, []);
  
    return (
      <>
        <h1 className="top_header">Contact</h1>
        <div className="body_of_text">
          <div id="instructions">
            <p>
              Interested in the goods and services we provide? Want a custom order or job done?
              You can reach us with the form below!
            </p>
          </div>
        </div>
        <Form />
      </>
    );
  }
  

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            subject: "",
            body: "",
            captchaComplete: false,
            token: null,
            formComplete: false
        };
        this.captchaRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.capVerified = this.capVerified.bind(this);
        this.isformComplete = this.isFormComplete.bind(this);
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value,
        }, () => {
        this.isformComplete();
        })
    }
    isFormComplete() {
        const submitButton = document.getElementById("submitButton"); 
        if (this.state.body !== "" && this.state.subject !== "" && this.state.email !== "" && this.state.captchaComplete) {
            this.setState({formComplete: true});
            submitButton.classList.remove("blocked");
        } else {
            this.setState({formComplete: false}); 
            submitButton.classList.add("blocked");
        }
    }
    capVerified() {
        this.setState({captchaComplete: true
        }, () => {
        this.isformComplete();
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const submitButton = document.getElementById("submitButton"); 

        if (!this.state.formComplete || !this.state.captchaComplete) {
            alert("Fill out form and pass captcha.");
        } else {
            if (!this.token) {
                console.log("update this");
                this.setState({token: this.captchaRef.current.getValue()})
            }

            emailjs.sendForm("service_8urcwf6", "template_8glaudm", ".f", "KCLLg0SSvKLfZMkbx")
            .then((result) => {
                console.log("Form successfully sent!");
            }, (error) => {
                console.log("FORM ERROR!");
            });

            var inputs = document.getElementsByTagName("input")
            for (var i = 0; i < inputs.length; i++) 
                inputs[i].disabled = true;
            var textareas = document.getElementsByTagName("textarea")
            for (var j = 0; j < textareas.length; j++) 
                textareas[j].disabled = true;

            var t = setInterval(() => {
                if (submitButton.value === "✓" && submitButton.disabled) {
                    clearTimeout(t);
                }
                submitButton.value = "✓";
                submitButton.style.color = "black";
                submitButton.style.backgroundColor = "#00cc00";
                submitButton.disabled = true;
                console.log("applying");
            }, 100)
        }
    }

    render() {
        return (
            <form className="f" onSubmit={this.handleSubmit}>
                <div className="form_container">
                    <label>E-mail: 
                    <input name="email" type="text" value={this.state.email} 
                    onChange={this.handleInputChange} placeholder="johndoe@gmail.com" autoComplete="off"/>
                    </label>

                    <label>Subject:
                    <input name="subject" type="text" value={this.state.subject} 
                    onChange={this.handleInputChange} placeholder="Custom Table" autoComplete="off"/>
                    </label>
                    
                    <lable>Body:
                    <textarea name="body" type="text" value={this.state.body} 
                    onChange={this.handleInputChange} placeholder="Your text here..." autoComplete="off"/>
                    </lable>
                </div>
                <div className="end">                                                
                    <ReCAPTCHA id="rec" ref={this.captchaRef} theme="light" sitekey="6LeTOzsnAAAAAJEUwsv8_MW3myyxAM_r9mGShQUJ" onChange={this.capVerified}/>
                    <div id="s"><input id="submitButton" type="submit" value="Submit" class="blocked"/></div>
                </div>
                <div name="g-recaptcha-response" value={this.token}/>
            </form>
        )
    }
}