import React from 'react'
import {ImageGallery} from "react-image-grid-gallery";

// Update this when adding images
const maxImages = 26

// Randomize Order of Images in Gallery //
let t = [];
let order = [];
for (let i = 1; i <= maxImages; i++) {t.push(i);} 
while (t.length !== 0) {
  let idx = Math.floor(Math.random() * t.length);
  order.push(t[idx]);
  t = t.slice(0, idx).concat(t.slice(idx+1));
}

export default function Glry() {
  return (
    <>
      <h1 className="top_header">Gallery</h1>
      <img id="spin" src="Spin.gif" alt="Spinning Icon"></img>
      <Pictures/>
    </>
  )

  function Pictures() {
    const images = [];
    for (let i = 0; i < order.length; i++) {
      images.push({src: "imgs/compressed/" + order[i] + ".webp"});
    }

    // makes sure all images load
    const smoothLoad = setInterval(() => {
      const all_images = document.querySelectorAll(`img[src*='imgs/compressed/']`);
      let loadedCount = 0;
    
      Array.from(all_images).forEach(image => {
        if (image.complete) loadedCount++;
      });

      if (loadedCount === maxImages) {
        document.querySelector("#spin").style.display = "none";
        document.querySelector(".imgs").style.opacity = 1;
        clearInterval(smoothLoad);
      }
    }, 100);

    // load high-quality images
    function isDigit(char) {
      return /^[0-9]$/.test(char);
    }

    document.addEventListener('click', function(e) {
      if (e.target.tagName === "IMG" && e.target.src.includes("imgs/compressed")) {
        const src = e.target.src.toString();

        let num = "";
        for (let i = 0; i < src.length; i++) {
          if (isDigit(src[i])) num += src[i]
        }

        // replace low-res image with high-res original
        const imgs = document.querySelectorAll(`img[src='imgs/compressed/${num}.webp']`);
        imgs.forEach(img => {
          img.src = `imgs/${num}.jpg`;
        });

        // update images array
        for (let i = 0; i < images.length; i++) {
          if (images[i].src === `imgs/compressed/${num}.webp`) {
            images[i].src = `imgs/${num}.jpg`;
            break;
          }
        }
      
      }
    });

    return (
      <>
        <div class="imgs">
          <ImageGallery
            imagesInfoArray={images}
            columnCount="auto"
            columnWidth={350}
            gapSize={10}
          />
        </div>
      </>
    ); 
  }
} 
