import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './App';
import './style.css';
import {BrowserRouter} from 'react-router-dom';

document.title = "Yspish Inc.";
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render (
        <React.StrictMode>
                <BrowserRouter>
                        <App/>
                </BrowserRouter>
        </React.StrictMode>
)