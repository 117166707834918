import React from 'react';
import NavBar from './components/navbar';
import About from './pages/about';
import Contact from './pages/contact';
import Glry from './pages/gallery';
import PageNotFound from './pages/pagenotfound';
import {Route, Routes} from 'react-router-dom';

function App() {
  return (
    <>
      <NavBar/>
      <div className="container">
        <Routes>
            <Route exact path="/" element={<About/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/contact" element={<Contact/>}/>
            <Route exact path="/gallery" element={<Glry/>}/>
            <Route path="/*" element={<PageNotFound/>}/>
        </Routes>
      </div>
    </>
  )
}
export default App;