import React from 'react';
import {Link, useMatch, useResolvedPath} from 'react-router-dom';

export default function NavBar() {
    return(
        <nav className="nav">
          <div className="top">
            <Link to="/about" className="title">Yspish Inc.</Link>
          </div>
          <ul>
            <CustomLink to="/gallery">Gallery</CustomLink>
            <CustomLink to="/about">About</CustomLink>
            <CustomLink to="/contact">Contact</CustomLink>
          </ul>
      </nav>
    )
}

function CustomLink({to, children, ...props}) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({path: resolvedPath.pathname, end: true});
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>{children}</Link>
    </li>
  )
}